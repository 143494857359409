import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6d396a26&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBox: require('/vercel/path0/components/SearchBox.vue').default,Container: require('/vercel/path0/components/common/Container.vue').default,AppHero: require('/vercel/path0/components/common/AppHero.vue').default})
